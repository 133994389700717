/* You can add global styles to this file, and also import other style files */
@import '~ng-zorro-antd/ng-zorro-antd.min.css';

.fill-remaining-space {
  /* This fills the remaining space, by using flexbox. 
   Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.multilevel-menu-custom-class {
  display: none !important;
}

body::-webkit-scrollbar,
body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-track,
.container-ui {
  padding-left: 24px;
  padding-top: 24px;
}

.action {
  flex: 0 0 100px;
}

html {
  font-size: 14px;
}
